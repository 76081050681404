import React from 'react';
import { useParams } from 'react-router-dom';
// import { ProjectTitle } from '../../components/ProjectTitle';
import { ProjectMenu } from '../../components/ProjectMenu';
import { useAppStore } from 'store/app-store.hook';
import { observer } from 'mobx-react-lite';
import { Helmet } from 'react-helmet';
import { TableToolbar } from 'components/TableToolbar';
import { PERIOD_SELECT_OPTIONS } from 'types/select-types';
import { ProjectNavigtaion } from './ProjectNavigation';
import moment from 'moment';

export const ProjectInvoicesPage: React.FC = observer(() => {
  const { projectOverviewStore, timelineStore } = useAppStore();
  // const { project } = projectOverviewStore;
  const { id } = useParams<{ id: string }>();

  React.useEffect(() => {
    projectOverviewStore.loadProject({
      id,
      from: moment(timelineStore.period.start).format('YYYY-MM-DD'),
      to: moment(timelineStore.period.end).format('YYYY-MM-DD'),
    });
  }, [id, projectOverviewStore, timelineStore.period]);

  return (
    <div>
      <Helmet>{/* <title>{project.clientName} | Invoices</title> */}</Helmet>
      <div className="top-panel mb-14">
        <div className="top-bar d-flex flex-wrap flex-xl-nowrap justify-content-between align-items-top">
          <div className="d-xl-inline-block">
            {/* <ProjectTitle
              name={project.name}
              agency={{ id: project.agencyId, agencyName: project.agencyName }}
              client={{ id: project.clientId, clientName: project.clientName }}
            /> */}
          </div>
          <div className="submenu-holder text-nowrap">
            <ProjectNavigtaion id={id} />
          </div>
          <div className="text-right">
            <ProjectMenu />
          </div>
        </div>
        <TableToolbar
          goPrev={timelineStore.goPrev}
          goNext={timelineStore.goNext}
          goToday={timelineStore.goToday}
          onViewChange={timelineStore.onViewChange}
          periodTitle={timelineStore.periodTitle}
          selectedView={timelineStore.selectedView}
          options={PERIOD_SELECT_OPTIONS}
        />
      </div>
    </div>
  );
});
