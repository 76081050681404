import React from 'react';
import classNames from 'classnames';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { Button, Col, Form, FormFeedback, FormGroup, Input, Row, CustomInput } from 'reactstrap';
import ReactPhoneInput from 'react-phone-input-2';
import { validators, validate } from '../../../utils/form-utils';
import { ReactComponent as Logo } from '../../../svg/mt-logo-dark.svg';
import { Step1ProjectRequest } from 'types/request.types';
import styles from './StartDemo1.module.scss';
import { STATIC_SITE_URI } from 'config';

const validationSchema = () =>
  Yup.object()
    .shape({
      firstName: validators.firstName,
      lastName: validators.lastName,
      email: validators.email,
      phoneNumber: validators.phoneNumber,
      companyName: validators.companyName,
      websiteUrl: validators.websiteUrl,
      privacyPolicyAccepted: Yup.boolean().notRequired().nullable(),
      optOutOfEmails: Yup.boolean().notRequired().nullable(),
    })
    .required();

interface Props {
  requestStep1: Step1ProjectRequest;
  silo?: string;
  onNext: (params: Step1ProjectRequest) => void;
}

export const StartDemo1: React.FC<Props> = ({ onNext, requestStep1, silo }) => {
  const onPhoneChange = (number: string) => {
    setFieldTouched('phoneNumber', true);
    setFieldValue('phoneNumber', number, true);
  };

  const formik = useFormik<Step1ProjectRequest>({
    enableReinitialize: true,
    initialValues: {
      ...requestStep1,
    },
    onSubmit: (v) => onNext(validationSchema().cast(v)),
    validate: validate<Step1ProjectRequest>(validationSchema),
  });
  const {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
    setFieldValue,
    setFieldTouched,
    isValid,
  } = formik;

  return (
    <Form onSubmit={handleSubmit}>
      <div className="auth-box">
        <div className="auth-headline">
          <strong className="auth-logo">
            <Logo />
          </strong>
          <h1>Request a Demo</h1>
          <p>
            To help better understand your {silo ? <h4 className={styles.silo_text}>{silo}</h4> : 'business'} goals,
            we’d like to ask you a few questions.
          </p>
        </div>
        <Row>
          <Col sm={6}>
            <FormGroup
              className={classNames(
                'form-group-addon',
                { 'input-active': values.firstName },
                { 'input-invalid': touched.firstName && !!errors.firstName },
                { 'input-touched': touched.firstName },
                { 'input-valid': touched.firstName && !errors.firstName },
              )}
            >
              <label htmlFor="firstName">First Name*</label>
              <Input
                type="text"
                name="firstName"
                id="firstName"
                placeholder="First Name"
                valid={touched.firstName && !errors.firstName}
                invalid={touched.firstName && !!errors.firstName}
                required
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.firstName}
              />
              <FormFeedback>{errors.firstName}</FormFeedback>
            </FormGroup>
          </Col>
          <Col sm={6}>
            <FormGroup
              className={classNames(
                'form-group-addon',
                { 'input-active': values.lastName },
                { 'input-invalid': touched.lastName && !!errors.lastName },
                { 'input-touched': touched.lastName },
                { 'input-valid': touched.lastName && !errors.lastName },
              )}
            >
              <label htmlFor="lastName">Last Name*</label>
              <Input
                type="text"
                name="lastName"
                id="lastName"
                placeholder="Last Name"
                valid={touched.lastName && !errors.lastName}
                invalid={touched.lastName && !!errors.lastName}
                required
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.lastName}
              />
              <FormFeedback>{errors.lastName}</FormFeedback>
            </FormGroup>
          </Col>
        </Row>
        <FormGroup
          className={classNames(
            'form-group-addon',
            { 'input-active': values.email },
            { 'input-invalid': touched.email && !!errors.email },
            { 'input-touched': touched.email },
            { 'input-valid': touched.email && !errors.email },
          )}
        >
          <label htmlFor="email">Email*</label>
          <Input
            type="email"
            name="email"
            id="email"
            placeholder="Email address"
            autoComplete="email"
            valid={touched.email && !errors.email}
            invalid={touched.email && !!errors.email}
            required
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.email}
          />
          <i className="glyphicon glyphicon-user form-control-feedback" />
          <FormFeedback>{errors.email}</FormFeedback>
        </FormGroup>
        <FormGroup
          className={classNames(
            'form-group-addon',
            { 'input-active': values.phoneNumber },
            { 'input-invalid': touched.phoneNumber && !!errors.phoneNumber },
            { 'input-touched': touched.phoneNumber },
            { 'input-valid': touched.phoneNumber && !errors.phoneNumber },
          )}
        >
          <label>Phone number</label>
          <ReactPhoneInput
            containerClass={classNames(
              'react-tel-input form-control',
              { 'is-active': values.phoneNumber },
              { 'is-invalid': touched.phoneNumber && !!errors.phoneNumber },
              { 'is-touched': touched.phoneNumber },
              { 'is-valid': touched.phoneNumber && !errors.phoneNumber },
            )}
            inputExtraProps={{
              name: 'phone',
              required: true,
            }}
            defaultCountry="us"
            placeholder="(000) 000-0000"
            value={values.phoneNumber}
            onChange={onPhoneChange}
            disableAreaCodes
          />
          <i className="glyphicon glyphicon-user form-control-feedback" />
          <FormFeedback className="order-3">{errors.phoneNumber}</FormFeedback>
        </FormGroup>
        <Row>
          <Col sm={6}>
            <FormGroup
              className={classNames(
                'form-group-addon',
                { 'input-active': values.companyName },
                {
                  'input-invalid': touched.companyName && !!errors.companyName,
                },
                { 'input-touched': touched.companyName },
                { 'input-valid': touched.companyName && !errors.companyName },
              )}
            >
              <label htmlFor="companyName">Company Name*</label>
              <Input
                type="text"
                name="companyName"
                id="companyName"
                placeholder="Company Name"
                valid={touched.companyName && !errors.companyName}
                invalid={touched.companyName && !!errors.companyName}
                required
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.companyName}
              />
              <FormFeedback>{errors.companyName}</FormFeedback>
            </FormGroup>
          </Col>
          <Col sm={6}>
            <FormGroup
              className={classNames(
                'form-group-addon',
                { 'input-active': values.websiteUrl },
                {
                  'input-invalid': touched.websiteUrl && !!errors.websiteUrl,
                },
                { 'input-touched': touched.websiteUrl },
                { 'input-valid': touched.websiteUrl && !errors.websiteUrl },
              )}
            >
              <label htmlFor="websiteUrl">Website URL</label>
              <Input
                type="text"
                name="websiteUrl"
                id="websiteUrl"
                placeholder="Website"
                valid={touched.websiteUrl && !errors.websiteUrl}
                invalid={touched.websiteUrl && !!errors.websiteUrl}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.websiteUrl || ''}
              />
              <FormFeedback>{errors.websiteUrl}</FormFeedback>
            </FormGroup>
          </Col>
        </Row>
        <CustomInput
          type="checkbox"
          id="private-policy"
          name="private-policy"
          label={
            <div>
              I have read and agree to MindTrust's{' '}
              <a target="_blank" href={`${STATIC_SITE_URI}/privacy-policy`}>
                Private Policy
              </a>
            </div>
          }
          onChange={() => {
            setFieldValue('privacyPolicyAccepted', !values.privacyPolicyAccepted, true);
          }}
          checked={!!values.privacyPolicyAccepted}
        />
        <FormGroup>
          <CustomInput
            type="radio"
            id="receive-radio"
            className="mt-2"
            label="I would like to receive MindTrust Emails and promos"
            required
            onChange={() => {
              setFieldValue('optOutOfEmails', false, true);
            }}
            checked={!values.optOutOfEmails}
          />
          <CustomInput
            className="mt-2"
            type="radio"
            id="not-receive-radio"
            label="I opt out of MindTrust and special offers"
            required
            onChange={() => {
              setFieldValue('optOutOfEmails', true, true);
            }}
            checked={!!values.optOutOfEmails}
          />
        </FormGroup>
      </div>
      <div className="auth-actions">
        <FormGroup className="d-flex justify-content-center">
          <Button
            className="btn-block"
            type="submit"
            color="primary"
            disabled={!isValid || !values.privacyPolicyAccepted}
            onClick={handleSubmit}
          >
            Next<i className="btn-icon-next"></i>
          </Button>
        </FormGroup>
      </div>
    </Form>
  );
};
