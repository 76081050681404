import { observable, computed, reaction, action } from 'mobx';
import { PeriodRange } from 'types/timeline-types';
import { getPeriodByView, getViewFromUrl, getUrlParamsFromRange, getPeriodFromUrl } from './timeline-utils';
import { RouterStore } from 'mobx-react-router';
import moment from 'moment';
import { DateGrouping } from 'types/gql-generated';
import { PERIOD_SELECT_OPTIONS } from 'types/select-types';

export class TimelineStore {
  @observable private routerStore: RouterStore;
  @observable private _period: PeriodRange = getPeriodByView(DateGrouping.MONTH);

  constructor(routerStore: RouterStore) {
    this.routerStore = routerStore;
    reaction(
      () => this.selectedView,
      (view: DateGrouping) => this.setPeriod(view),
    );
  }

  @computed
  get selectedView(): DateGrouping {
    const { search } = this.routerStore.location;
    return getViewFromUrl(search as string);
  }

  @computed
  private get selectedViewString(): 'week' | 'month' | 'day' {
    const view = this.selectedView;
    if (view === DateGrouping.WORK_WEEK) return 'week';
    if (view === DateGrouping.MONTH) return 'month';
    if (view === DateGrouping.DAY) return 'day';
    return 'month';
  }

  @computed
  get period(): PeriodRange {
    const { search } = this.routerStore.location;
    return getPeriodFromUrl(search) || this._period;
  }

  @computed
  get toolbar() {
    return {
      goPrev: this.goPrev,
      goNext: this.goNext,
      goToday: this.goToday,
      onViewChange: this.onViewChange,
      periodTitle: this.periodTitle,
      selectedView: this.selectedView,
      options: PERIOD_SELECT_OPTIONS,
    };
  }

  @action
  setPeriod = (view: DateGrouping) => {
    const { pathname } = this.routerStore.location;
    const search = `?view=${this.selectedView}&${getUrlParamsFromRange(getPeriodByView(view))}`;
    this.routerStore.push(`${pathname}${search}`);
  };

  @computed
  get periodTitle(): string {
    return '';
  }

  @action
  onViewChange = (newView: DateGrouping) => {
    const { pathname } = this.routerStore.location;
    const search = `?view=${newView}&${getUrlParamsFromRange(this.period)}`;
    this.routerStore.push(`${pathname}${search}`);
  };

  @action
  goPrev = () => {
    const { pathname } = this.routerStore.location;
    const start = moment(this.period.start).add(-1, this.selectedViewString);
    const search = `?view=${this.selectedView}&${getUrlParamsFromRange({
      start: start.toDate(),
      end: start.clone().endOf(this.selectedViewString).toDate(),
    })}`;
    this.routerStore.push(`${pathname}${search}`);
  };

  @action
  goNext = () => {
    const { pathname } = this.routerStore.location;
    const start = moment(this.period.start).add(1, this.selectedViewString);
    const search = `?view=${this.selectedView}&${getUrlParamsFromRange({
      start: start.toDate(),
      end: start.clone().endOf(this.selectedViewString).toDate(),
    })}`;
    this.routerStore.push(`${pathname}${search}`);
  };

  @action
  goToday = () => {
    const { pathname } = this.routerStore.location;
    const start = moment().startOf(this.selectedViewString);
    const search = `?view=${this.selectedView}&${getUrlParamsFromRange({
      start: start.toDate(),
      end: start.clone().endOf(this.selectedViewString).toDate(),
    })}`;
    this.routerStore.push(`${pathname}${search}`);
  };
}
