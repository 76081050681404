import React from 'react';
import { observer } from 'mobx-react-lite';
import { TimeZoneSelect } from 'components/TimeZoneSelect';
import { MeetingSlot } from 'types/project-request-types';
import cn from 'classnames';
import styles from './TimeSlotPicker.module.scss';

interface Props {
  className?: string;
  selected: MeetingSlot | null;
  slots: MeetingSlot[];
  onSelect: (slot: MeetingSlot) => void;
  onTimezoneChange: (offset: number) => void;
}

export const TimeSlotPicker: React.FC<Props> = observer(
  ({ className, selected, slots, onSelect, onTimezoneChange }) => {
    return (
      <div className={cn(styles.wrapper, className, { [styles.with_border]: !!slots.length })}>
        <div className={styles.header}>
          What time works best <TimeZoneSelect onTimezoneChange={onTimezoneChange} />
        </div>
        <div className="d-flex flex-wrap">
          {slots.map((slot, i) => (
            <div
              key={i}
              className={cn(styles.time_slot, { [styles.selected]: selected === slot })}
              onClick={() => onSelect(slot)}
            >
              {slot.utcWithOffset.format('hh:mm A')}
            </div>
          ))}
        </div>
      </div>
    );
  },
);
