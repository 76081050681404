import { computed, observable, action } from 'mobx';
import { ToastStore } from 'store/toast-store';
import { RouterStore } from 'mobx-react-router';
import { ProjectTeamEntity } from 'store/entities/project-entities';
import { ProjectsService } from 'store/services/projects-service';
import queryString from 'query-string';
import {
  ProjectOverviewQLVariables,
  ProjectOverviewQL_projectOverview_project,
  ProjectOverviewQL_projectOverview_team,
} from 'types/gql-generated/ProjectOverviewQL';
import { BudgetInfoEntity } from 'store/entities/budget-info-entity';

export class ProjectQuickLinksStore {
  private projectsService: ProjectsService;
  private toastStore: ToastStore;
  @observable private _team: ProjectOverviewQL_projectOverview_team[] = [];
  @observable private _project: ProjectOverviewQL_projectOverview_project = {
    id: '',
    name: '',
    clientId: '',
    clientName: '',
    agencyId: null,
    agencyName: null,
  };
  @observable private routerStore: RouterStore;

  constructor(projectsService: ProjectsService, routerStore: RouterStore, toastStore: ToastStore) {
    this.projectsService = projectsService;
    this.routerStore = routerStore;
    this.toastStore = toastStore;
  }

  get projectId() {
    return 1;
  }

  @computed
  get clientName() {
    return this._project.clientName;
  }

  @computed
  get title() {
    return {
      name: this._project.name,
      agency: { id: this._project.agencyId, agencyName: this._project.agencyName },
      client: { id: this._project.clientId, clientName: this._project.clientName },
    };
  }

  @computed
  get projectTeam() {
    const { search } = this.routerStore.location;
    const { view } = queryString.parse(search);
    return { items: this._team.map((m) => new ProjectTeamEntity(m, this.toastStore, view as string)) };
  }

  @computed
  get budgetInfo() {
    const { search } = this.routerStore.location;
    const { view } = queryString.parse(search);
    const { budget, bonus, teamTime, billableExpenses, remainingBudget, getMoreHours } = new BudgetInfoEntity(
      this._project,
      this.toastStore,
      view as string,
    );
    return { budget, bonus, teamTime, billableExpenses, remainingBudget, getMoreHours };
  }

  @action
  loadProject = async (input: ProjectOverviewQLVariables['input']) => {
    const { team, project } = await this.projectsService.loadProjectOverview(input);
    this._team = team;
    this._project = project;
  };

  @action
  getMoreHours = () => {
    this.toastStore.showSuccessMessage('More hours reguested');
  };
}
