import React from 'react';
import { observer } from 'mobx-react-lite';
import Select, { components, ValueType } from 'react-select';
import {
  getDefaultTimeZone,
  getTimezoneName,
  getUtcDisplay,
  groupByRegion,
  REGIONS,
} from '../../utils/time-zone-utils/timezones';
import './TimeZoneSelect.scss';

const Group = (props: any) => (
  <div>
    <components.Group {...props} />
  </div>
);

const groupedOps = REGIONS.map((region) => ({
  label: region,
  options: groupByRegion[region].map(({ offset }) => ({
    label: `UTC ${getUtcDisplay(offset)} ${getTimezoneName(offset, region)}`,
    value: getUtcDisplay(offset),
    offset,
  })),
}));

interface Props {
  className?: string;
  onTimezoneChange: (offset: number) => void;
}

const defaultTimezone = getDefaultTimeZone();

interface OptionType {
  label: string;
  value: string;
  offset: number;
}

export const TimeZoneSelect: React.FC<Props> = observer(({ className, onTimezoneChange }) => {
  const [selected, setSelected] = React.useState(
    groupedOps.reduce<OptionType | null>((option, next) => {
      if (next.label !== defaultTimezone.region) return option;
      const result = next.options.find(({ offset }) => offset === defaultTimezone.offset);
      return result || option;
    }, null),
  );

  const onChange = (valueT: ValueType<OptionType>) => {
    if (valueT) {
      // @ts-ignore
      setSelected(valueT);
    }
  };

  React.useEffect(() => {
    if (selected) onTimezoneChange(selected.offset * 60);
  }, [onTimezoneChange, selected]);

  return (
    <div className={className}>
      <Select
        className="timezone-select"
        classNamePrefix="timezone"
        value={selected}
        options={groupedOps}
        components={{ Group }}
        onChange={onChange}
      />
    </div>
  );
});
