import React from 'react';
import { Helmet } from 'react-helmet';
import { observer } from 'mobx-react-lite';
import { StartProject3 } from 'components/ProjectRequest';
import { useAppStore } from 'store/app-store.hook';

export const ProjectRequest3Page = observer(() => {
  const { projectRequestPage } = useAppStore().ui;

  return (
    <>
      <Helmet>
        <title>Request Project</title>
      </Helmet>
      <StartProject3
        silo={projectRequestPage.silo}
        requestStep3={projectRequestPage.projectRequest}
        onNext={projectRequestPage.process3Step}
        goBack={projectRequestPage.goBack}
      />
    </>
  );
});
