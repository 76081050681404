import React from 'react';
import { Button } from 'reactstrap';
import styles from './QuicklinkCreate.module.scss';

interface Props {
  onCreate: () => void;
}

export const QuicklinkCreate: React.FC<Props> = ({ onCreate }) => {
  return (
    <div className={styles.quicklink_box}>
      <i className={styles.icon_anchor}></i>
      <h2>Create your first Quicklink</h2>
      <p className="mb-24">Quicklinks allow you to easily access a url relavant to the project.</p>
      <Button color="primary" onClick={onCreate}>
        Create Quicklink
      </Button>
    </div>
  );
};
