import React from 'react';
import classNames from 'classnames';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { Button, Col, Form, FormGroup, Row } from 'reactstrap';
import { Link, useLocation } from 'react-router-dom';
import { ReactComponent as Logo } from '../../../svg/mt-logo-dark.svg';
import { validate } from 'utils/form-utils';
import { requestDemoRoutes, requestProjectRoutes } from 'routing';
import { MultipleSelect } from 'components/MultipleSelect/MultipleSelect';
import { Step2ServicesProjectRequest } from 'types/request.types';
import { observer } from 'mobx-react-lite';

const validationSchema = () =>
  Yup.object()
    .shape({
      services: Yup.array().of(Yup.string().required()).nullable().notRequired(),
    })
    .required();

interface Props {
  requestStep2: Step2ServicesProjectRequest;
  services: string[];
  onNext: (params: Step2ServicesProjectRequest) => void;
  goBack: () => void;
}

export const StartProject2Services: React.FC<Props> = observer(({ requestStep2, services, onNext, goBack }) => {
  const { search } = useLocation();

  const formik = useFormik<Step2ServicesProjectRequest>({
    enableReinitialize: true,
    initialValues: {
      ...requestStep2,
    },
    onSubmit: onNext,
    validate: validate<Step2ServicesProjectRequest>(validationSchema),
  });

  const { values, handleSubmit, setFieldValue } = formik;

  const onSelect = React.useCallback(
    (values: string[]) => {
      setFieldValue('services', values, true);
    },
    [setFieldValue],
  );

  return (
    <Form onSubmit={handleSubmit}>
      <div className="auth-box">
        <div className="auth-headline">
          <strong className="auth-logo">
            <Logo />
          </strong>
          <h1>What type of project is it?</h1>
          <p>
            Select from the list of search bellow tell us about your project needs. Or choose from{' '}
            <Link to={`${requestProjectRoutes.request(2)}/resources${search}`}>specific team members</Link> if you know
            exactly what skills you're looking for.
          </p>
        </div>
        <FormGroup className={classNames('form-group-addon')}>
          <MultipleSelect options={services} selected={values.services || []} onSelect={onSelect} />
        </FormGroup>
      </div>
      <div className="auth-actions">
        <FormGroup>
          <Row className="gap-xs">
            <Col xs={6}>
              <Button className="btn-block c-gray" color="default" onClick={goBack}>
                <i className="btn-icon-back" />
                Back
              </Button>
            </Col>
            <Col xs={6}>
              <Button
                className="btn-block"
                type="submit"
                color="primary"
                disabled={!values.services?.length}
                onClick={handleSubmit}
              >
                Next
                <i className="btn-icon-next" />
              </Button>
            </Col>
          </Row>
        </FormGroup>
      </div>
      <p className="help-text">
        Need help? <Link to={requestDemoRoutes.request(2)}>Speak with a Sales Consultant.</Link>
      </p>
    </Form>
  );
});
