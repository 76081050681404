import { GQLConnector } from 'store/qgl-connector';
import * as projectsQueiries from '../../queries/projects-queries';
import { ProjectsQLVariables, ProjectsQL } from 'types/gql-generated';
import { ProjectOverviewQLVariables, ProjectOverviewQL } from 'types/gql-generated/ProjectOverviewQL';
import { ProjectDetailsQLVariables, ProjectDetailsQL } from 'types/gql-generated/ProjectDetailsQL';

export class ProjectsService {
  private qglConnector: GQLConnector;

  constructor(qglConnector: GQLConnector) {
    this.qglConnector = qglConnector;
  }

  loadProjects = async (input: ProjectsQLVariables['input']): Promise<ProjectsQL['projects']> =>
    (
      await this.qglConnector.mutate<ProjectsQL>({
        mutation: projectsQueiries.PROJECTS,
        variables: { input },
      })
    ).projects;

  loadProjectOverview = async (
    input: ProjectOverviewQLVariables['input'],
  ): Promise<ProjectOverviewQL['projectOverview']> =>
    (
      await this.qglConnector.query<ProjectOverviewQL, ProjectOverviewQLVariables>({
        query: projectsQueiries.PROJECT_OVERVIEW,
        variables: { input },
      })
    ).projectOverview;

  loadProjectDetails = async (input: ProjectDetailsQLVariables['input']): Promise<ProjectDetailsQL['projectDetails']> =>
    (
      await this.qglConnector.query<ProjectDetailsQL, ProjectDetailsQLVariables>({
        query: projectsQueiries.PROJECT_DETAILS,
        variables: { input },
      })
    ).projectDetails;

  // syncSilo = async (): Promise<SyncSiloQL['syncSilo']> =>
  //   (
  //     await this.qglConnector.mutate<SyncSiloQL>({
  //       mutation: projectsQueiries.MUTATION_SYNC_SILO,
  //     })
  //   ).syncSilo;
}
