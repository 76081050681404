import gql from 'graphql-tag';

export const ADD_PROJECT_REQUEST_MUTATION = gql`
  mutation AddProjectRequestMutation($input: ProjectRequestInput!) {
    addProjectRequest(input: $input) {
      phoneNumber
      companyName
      websiteUrl
      type
      services
      skills
      budgetFromUsd
      budgetToUsd
      whenToStart
      additionalDetails
      firstName
      lastName
      email
      id
      createdAt
      optOutOfEmails
      privacyPolicyAccepted
    }
  }
`;

export const SEARCH_SILO_SERVICES_QUERY = gql`
  query SearchSiloServicesQuery($input: SiloSearchInput!) {
    searchSiloServices(input: $input) {
      service
    }
  }
`;

export const SEARCH_SILO_SKILLS_QUERY = gql`
  query SearchSiloSkillsQuery($input: SiloSearchInput!) {
    searchSiloSkills(input: $input) {
      skill
    }
  }
`;

export const SYNC_SILO_MUTATION = gql`
  mutation SyncSiloMutation {
    syncSilo {
      skills
      services
    }
  }
`;

export const BOOK_MEETING_INFO_QUERY = gql`
  query BookMeetingInfoQuery {
    bookMeetingInfo {
      timezone
      durations
      linkAvailabilityByDuration {
        meetingDurationMillis
        availabilities {
          startMillisUtc
          endMillisUtc
        }
      }
    }
  }
`;

export const BOOK_MEETING_MUTATION = gql`
  mutation BookMeetingMutation($input: BookMeetingInput!) {
    bookMeeting(input: $input) {
      start
      end
      duration
      subject
      contact {
        firstName
        lastName
        email
      }
      organizer {
        firstName
        lastName
        email
      }
    }
  }
`;
