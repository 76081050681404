import React from 'react';
import { ReactComponent as Logo } from '../../../svg/mt-logo-dark.svg';
import { CheckEmailIcon } from '../../SvgIcons/CheckEmail';
import { Button } from 'reactstrap';
import { BookedMeeting } from 'types/request.types';
import moment from 'moment';

interface Props {
  bookedMeeting: BookedMeeting;
  onResend: () => void;
}

export const StartDemo3: React.FC<Props> = ({ bookedMeeting, onResend }) => {
  const { firstName, lastName } = bookedMeeting.organizer;
  return (
    <div>
      <div className="auth-box d-flex flex-column">
        <div className="auth-headline mb-0">
          <strong className="auth-logo">
            <Logo />
          </strong>
        </div>
        <div className="auth-message text-center">
          <CheckEmailIcon />
          <h1 className="mt-20">Booking confirmed</h1>
          <p>
            You’re booked with {`${firstName} ${lastName}`}.
            <br />
            An inventation has been emailed to you.
          </p>
          <p>
            <strong>{moment(bookedMeeting.start).format('MMMM DD, YYYY hh:mm A')}</strong>
          </p>
        </div>
      </div>
      <p className="help-text pt-48 d-flex align-items-center">
        Didn’t receive an email?{' '}
        <Button color="link" onClick={onResend}>
          Click to resend
        </Button>
      </p>
    </div>
  );
};
