import { observable, computed, action } from 'mobx';
import { ToastStore } from 'store/toast-store';
import { ProjectOverviewQL_projectOverview_project } from 'types/gql-generated/ProjectOverviewQL';
import { ProjectsView } from 'types/project-types';
import { addDollars, addHours } from 'utils/text-utils';

interface ProjectInfo extends ProjectOverviewQL_projectOverview_project {}

export class BudgetInfoEntity {
  @observable private _project: ProjectOverviewQL_projectOverview_project;
  @observable private toastStore: ToastStore;
  @observable private view: string | null;

  constructor(project: ProjectInfo, toastStore: ToastStore, view: string | null) {
    this._project = project;
    this.toastStore = toastStore;
    this.view = view;
  }

  @computed
  get budget() {
    return this.view === ProjectsView.TIME ? addHours(0) : addDollars(0);
  }

  @computed
  get bonus() {
    return this.view === ProjectsView.TIME ? addHours(0) : addDollars(0);
  }

  @computed
  get teamTime() {
    return this.view === ProjectsView.TIME ? addHours(0) : addDollars(0);
  }

  @computed
  get billableExpenses() {
    return this.view === ProjectsView.TIME ? addHours(0) : addDollars(0);
  }
  @computed
  get remainingBudget() {
    return this.view === ProjectsView.TIME ? addHours(0) : addDollars(0);
  }

  @action
  getMoreHours = () => {
    this.toastStore.showSuccessMessage('More hours reguested');
  };
}
