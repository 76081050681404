import { ServicesStore } from 'store/services/services.store';
import { Review } from 'types/reviews.types';
import { DomainsStore } from './domains.store';

export const reviews = [
  {
    headline: 'TEST MESSAGE 1',
    stars: 5,
    quote: `Without the structure of a process and accountability, companies fall into innovation cycles that are 10x as long as Checkmate's design sprint process, or worse, they never quite get there.`,
    avatar: 'assets/img/avatars/pic-andre-2x.jpg',
    author: 'Enrique Hernandez',
    company: 'CEO &amp; Co-Founder @ CompanyName',
  },
  {
    headline: 'TEST MESSAGE 2',
    stars: 5,
    quote: `Without the structure of a process and accountability, companies fall into innovation cycles that are 10x as long as Checkmate's design sprint process, or worse, they never quite get there.`,
    avatar: 'assets/img/avatars/pic-andre-2x.jpg',
    author: 'Enrique Hernandez',
    company: 'CEO &amp; Co-Founder @ CompanyName',
  },
  {
    headline: 'TEST MESSAGE 3',
    stars: 5,
    quote: `Without the structure of a process and accountability, companies fall into innovation cycles that are 10x as long as Checkmate's design sprint process, or worse, they never quite get there.`,
    avatar: 'assets/img/avatars/pic-andre-2x.jpg',
    author: 'Enrique Hernandez',
    company: 'CEO &amp; Co-Founder @ CompanyName',
  },
  {
    headline: 'TEST MESSAGE 4',
    stars: 5,
    quote: `Without the structure of a process and accountability, companies fall into innovation cycles that are 10x as long as Checkmate's design sprint process, or worse, they never quite get there.`,
    avatar: 'assets/img/avatars/pic-andre-2x.jpg',
    author: 'Enrique Hernandez',
    company: 'CEO &amp; Co-Founder @ CompanyName',
  },
];

export class ReviewsDomain {
  constructor(private domainStore: DomainsStore, private servicesStore: ServicesStore) {}

  getReview = (): Review => {
    return reviews[Math.floor(Math.random() * reviews.length)];
  };
}
