import React from 'react';
import classNames from 'classnames';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { Button, Col, CustomInput, Form, FormFeedback, FormGroup, Row } from 'reactstrap';
import { NavLink } from 'react-router-dom';
import { validate } from '../../../utils/form-utils';
import { ReactComponent as Logo } from '../../../svg/mt-logo-dark.svg';
import Select from 'react-select';
import { ProjectRequestType } from 'types/gql-generated';
import { requestDemoRoutes } from 'routing';
import { Step4ProjectRequest } from 'types/request.types';
import { existingProjectOptions, nonExistProjectOptions } from './budget-options';

const validationSchema = () =>
  Yup.object()
    .shape({
      budgetFromUsd: Yup.number().nullable().notRequired(),
      budgetToUsd: Yup.number().nullable().notRequired(),
      type: Yup.mixed()
        .oneOf([
          ProjectRequestType.EXISTING_PROJECT,
          ProjectRequestType.LEARN_ABOUT,
          ProjectRequestType.NEW_PROJECT,
          ProjectRequestType.ONGOING_ASSISTANCE,
        ])
        .nullable()
        .notRequired(),
    })
    .required();

interface Props {
  requestStep4: Step4ProjectRequest;
  onNext: (step4: Step4ProjectRequest) => void;
  goBack: () => void;
}

export const StartProject4: React.FC<Props> = ({ requestStep4, onNext, goBack }) => {
  const [knowBudget, setKnowBudget] = React.useState(true);

  const formik = useFormik<Step4ProjectRequest>({
    enableReinitialize: true,
    initialValues: { ...requestStep4 },
    onSubmit: onNext,
    validate: validate<Step4ProjectRequest>(validationSchema),
  });
  const { values, errors, touched, handleSubmit, setFieldValue } = formik;

  const options = !![ProjectRequestType.EXISTING_PROJECT, ProjectRequestType.ONGOING_ASSISTANCE].find(
    (t) => t === requestStep4.type,
  )
    ? existingProjectOptions
    : nonExistProjectOptions;

  const changeKnowBudget = React.useCallback(
    (flag: boolean) => () => {
      if (flag) {
        const { value } = options[0];
        setFieldValue('budgetFromUsd', value.budgetFromUsd, true);
        setFieldValue('budgetToUsd', value.budgetToUsd, true);
      }
      if (!flag) {
        setFieldValue('budgetFromUsd', null, true);
        setFieldValue('budgetToUsd', null, true);
      }
      setKnowBudget(flag);
    },
    [options, setFieldValue],
  );

  const onSelect = React.useCallback(
    (option) => {
      setFieldValue('budgetFromUsd', option.value.budgetFromUsd, true);
      setFieldValue('budgetToUsd', option.value.budgetToUsd, true);
    },
    [setFieldValue],
  );
  return (
    <Form onSubmit={handleSubmit}>
      <div className="auth-box">
        <div className="auth-headline">
          <strong className="auth-logo">
            <Logo />
          </strong>
          <h1>Do you have a budget in mind?</h1>
        </div>
        <FormGroup className="custom-radio-buttons">
          <Row className="gap-cards">
            <Col xs={6}>
              <CustomInput
                className="custom-icon icon-budget"
                type="radio"
                id="yesBudget"
                label="Yes I do"
                name="budget"
                required
                checked={knowBudget}
                onChange={changeKnowBudget(true)}
              />
            </Col>
            <Col xs={6}>
              <CustomInput
                className="custom-icon icon-nobudget"
                type="radio"
                id="noBudget"
                label="I don’t know"
                name="budget"
                required
                checked={!knowBudget}
                onChange={changeKnowBudget(false)}
              />
            </Col>
          </Row>
        </FormGroup>
        {knowBudget && (
          <FormGroup
            className={classNames(
              'form-group-addon',
              { 'input-active': values.budgetFromUsd || values.budgetToUsd },
              {
                'input-invalid':
                  (touched.budgetFromUsd || touched.budgetToUsd) && (!!errors.budgetFromUsd || !!errors.budgetToUsd),
              },
              { 'input-touched': touched.budgetFromUsd || touched.budgetToUsd },
              {
                'input-valid':
                  touched.budgetFromUsd && touched.budgetToUsd && !errors.budgetFromUsd && !errors.budgetToUsd,
              },
            )}
          >
            <label htmlFor="budget">What is your budget?</label>
            <Select
              className="w-100 select-container"
              classNamePrefix="select"
              id="budget"
              options={options}
              placeholder="Select your range"
              onChange={onSelect}
              value={options.find(({ value }) => value.budgetFromUsd === values.budgetFromUsd)}
            />
            <FormFeedback>{errors.budgetFromUsd || errors.budgetToUsd}</FormFeedback>
          </FormGroup>
        )}
      </div>
      <div className="auth-actions">
        <FormGroup>
          <Row className="gap-xs">
            <Col xs={6}>
              <Button className="btn-block c-gray" color="default" onClick={goBack}>
                <i className="btn-icon-back" />
                Back
              </Button>
            </Col>
            <Col xs={6}>
              <Button
                className="btn-block"
                type="submit"
                color="primary"
                disabled={knowBudget && !(values.budgetFromUsd || values.budgetToUsd)}
                onClick={handleSubmit}
              >
                Next<i className="btn-icon-next"></i>
              </Button>
            </Col>
          </Row>
        </FormGroup>
      </div>
      <p className="help-text">
        Need help? <NavLink to={requestDemoRoutes.request(2)}>Speak with a Sales Consultant.</NavLink>
      </p>
    </Form>
  );
};
