import { InviteParams } from 'types/referral-types';
import { ReferralService } from './services/referral-service';
import { observable } from 'mobx';
import { ToastStore } from './toast-store';

export class ReferralsStore {
  @observable private toastStore: ToastStore;
  @observable private referralService: ReferralService;

  constructor(referralService: ReferralService, toastStore: ToastStore) {
    this.referralService = referralService;
    this.toastStore = toastStore;
  }

  inviteUser = async (input: InviteParams) => {
    try {
      await this.referralService.invite(input);
      this.toastStore.showSuccessMessage('Invintation has been sent');
    } catch (error) {
      this.toastStore.showErrorMessage(error.message);
    }
  };
}
