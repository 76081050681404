import { computed, observable } from 'mobx';
import { Group } from '../types';
import { RouterStore } from 'mobx-react-router';
import { AuthStore } from './auth-store';
import { GQLConnector } from './qgl-connector';
import { AuthService } from './services/auth-service';
import { ReferralsStore } from './referrals-store';
import { ReferralService } from './services/referral-service';
import { PermissionStore } from './permisson-store/index';
import { NavigationStore } from './navigation-store';
import { ToastStore } from './toast-store';
import { ProjectsStore } from './projects-store';
import { ProjectDetailsStore, ProjectOverviewStore } from './project-overview-store';
import { ProjectsService } from './services/projects-service';
import { TimelineStore } from './timeline-store';
import { ProjectQuickLinksStore } from './project-overview-store/project-quicklinks-store';
import { UiStore } from './ui';
import { ServicesStore } from './services/services.store';
import { DomainsStore } from './domains/domains.store';

export const CLIENT_MOCK: Group[] = [
  {
    name: 'Starting Balance',
    categories: [],
  },
  {
    name: 'Credits',
    categories: [
      {
        name: 'Good Looks',
        costs: {
          'Jan 2020': 0.0,
          'Feb 2020': 0.0,
          'Mar 2020': 0.0,
          'Apr 2020': 0.0,
          'May 2020': 0.0,
          'Jun 2020': 0.0,
          'Jul 2020': 0.0,
          'Aug 2020': 0.0,
          'Sep 2020': 0.0,
          'Oct 2020': 0.0,
        },
      },
    ],
  },
  {
    name: 'Team',
    categories: [
      {
        name: 'Tribe Global - Deloitte',
        costs: {
          'Jan 2020': 0.0,
          'Feb 2020': 150.0,
          'Mar 2020': 150.0,
          'Apr 2020': 150.0,
          'May 2020': 150.0,
          'Jun 2020': 150.0,
          'Jul 2020': 150.0,
          'Aug 2020': 150.0,
          'Sep 2020': 150.0,
          'Oct 2020': 150.0,
        },
      },
      {
        name: 'Tribe Health Mobile',
        costs: {
          'Jan 2020': 0.0,
          'Feb 2020': 150.0,
          'Mar 2020': 150.0,
          'Apr 2020': 150.0,
          'May 2020': 150.0,
          'Jun 2020': 150.0,
          'Jul 2020': 150.0,
          'Aug 2020': 150.0,
          'Sep 2020': 150.0,
          'Oct 2020': 150.0,
        },
      },
      {
        name: 'Marketing Website',
        costs: {
          'Jan 2020': 0.0,
          'Feb 2020': 150.0,
          'Mar 2020': 150.0,
          'Apr 2020': 150.0,
          'May 2020': 150.0,
          'Jun 2020': 150.0,
          'Jul 2020': 150.0,
          'Aug 2020': 150.0,
          'Sep 2020': 150.0,
          'Oct 2020': 150.0,
        },
      },
      {
        name: 'Tribe Trace',
        costs: {
          'Jan 2020': 0.0,
          'Feb 2020': 150.0,
          'Mar 2020': 150.0,
          'Apr 2020': 150.0,
          'May 2020': 150.0,
          'Jun 2020': 150.0,
          'Jul 2020': 150.0,
          'Aug 2020': 150.0,
          'Sep 2020': 150.0,
          'Oct 2020': 150.0,
        },
      },
    ],
  },
  {
    name: 'Billable Expenses',
    categories: [
      {
        name: 'Travel',
        costs: {},
      },
      { name: 'Software', costs: {} },
      { name: 'Other - Reimbursable', costs: {} },
      { name: 'Other - Bank Service Char...', costs: {} },
      { name: 'Other - Credit Card Fees', costs: {} },
      { name: 'Meals and Entertainment', costs: {} },
      { name: 'Professional Fees', costs: {} },
      { name: 'Telephone & Internet', costs: {} },
    ],
  },
  {
    name: 'Net Cash Movement',
    categories: [],
  },
  {
    name: 'End Balance',
    categories: [],
  },
];

export const GROUPS_MOCK: Group[] = [
  {
    name: 'Starting Balance',
    categories: [],
  },
  {
    name: 'Credits',
    categories: [
      {
        name: 'Good Looks',
        costs: {
          'Jan 2020': 0.0,
          'Feb 2020': 0.0,
        },
      },
    ],
  },
  {
    name: 'Team',
    categories: [
      {
        name: 'Front End Developer',
        title: 'Juan E',
        costs: {
          'Jan 2020': 500,
          'Feb 2020': 500,
        },
      },
      {
        name: 'UX/ UI Designer',
        title: 'Oldemare A',
        costs: {
          'Jan 2020': 500,
        },
      },
      {
        name: 'Digital Marketer',
        costs: {
          'Jan 2020': 500,
        },
      },
      {
        name: 'Production Assistant',
        costs: {
          'Jan 2020': 500,
        },
      },
      {
        name: 'Product Strategist',
        costs: {
          'Jan 2020': 500,
        },
      },
    ],
  },
  {
    name: 'Billable Expenses',
    categories: [
      {
        name: 'Travel',
        costs: {},
      },
      { name: 'Software', costs: {} },
      { name: 'Other - Reimbursable', costs: {} },
      { name: 'Other - Bank Service Char...', costs: {} },
      { name: 'Other - Credit Card Fees', costs: {} },
      { name: 'Meals and Entertainment', costs: {} },
      { name: 'Professional Fees', costs: {} },
      { name: 'Telephone & Internet', costs: {} },
    ],
  },
  {
    name: 'Net Cash Movement',
    categories: [],
  },
  {
    name: 'End Balance',
    categories: [],
  },
];

export class AppStore {
  @observable private _groups = CLIENT_MOCK;
  private projectService: ProjectsService;
  @observable private gqlConnector: GQLConnector;
  @observable routerStore: RouterStore;
  @observable authStore: AuthStore;
  @observable referralsStore: ReferralsStore;
  @observable permissionStore: PermissionStore;
  @observable navigationStore: NavigationStore;
  @observable toastStore: ToastStore;
  @observable projectsStore: ProjectsStore;
  @observable projectOverviewStore: ProjectOverviewStore;
  @observable projectQuickLinksStore: ProjectQuickLinksStore;
  @observable projectDetailsStore: ProjectDetailsStore;
  @observable timelineStore: TimelineStore;
  private servicesStore: ServicesStore;
  private domainsStore: DomainsStore;
  @observable ui: UiStore;

  @observable isSyncing: boolean = false;

  constructor(routerStore: RouterStore) {
    this.toastStore = new ToastStore();
    this.routerStore = routerStore;
    this.timelineStore = new TimelineStore(routerStore);
    this.gqlConnector = new GQLConnector();
    this.referralsStore = new ReferralsStore(new ReferralService(this.gqlConnector), this.toastStore);
    this.authStore = new AuthStore(new AuthService(this.gqlConnector), routerStore, this.toastStore);
    this.projectService = new ProjectsService(this.gqlConnector);
    this.projectsStore = new ProjectsStore(this.projectService, this.routerStore, this.toastStore);
    this.projectOverviewStore = new ProjectOverviewStore(this.projectService, this.routerStore, this.toastStore);
    this.projectQuickLinksStore = new ProjectQuickLinksStore(this.projectService, this.routerStore, this.toastStore);
    this.projectDetailsStore = new ProjectDetailsStore(this.projectService, this.routerStore, this.toastStore);
    this.permissionStore = new PermissionStore(this.authStore);
    this.navigationStore = new NavigationStore(this.permissionStore, routerStore);

    this.servicesStore = new ServicesStore(this.gqlConnector);
    this.domainsStore = new DomainsStore(this.servicesStore);
    this.ui = new UiStore(routerStore, this.domainsStore);
  }

  @computed
  get groups() {
    return this._groups;
  }

  updateGroups = (groups: Group[]) => {
    this._groups = groups;
  };

  syncSilo = async () => {
    this.isSyncing = true;
    try {
      // await this.projectService.syncSilo();
      // this.toastStore.showSuccessMessage('Sync is complete!');
    } catch (error) {
      this.toastStore.showErrorMessage(error);
    }
    this.isSyncing = false;
  };
}
