import React from 'react';
import styles from './QuicklinkPopup.module.scss';
import { Button, Card, CardBody, CardFooter, CardHeader, Form, FormGroup, Input, FormFeedback } from 'reactstrap';

import { useFormik } from 'formik';
import * as Yup from 'yup';
import { validate, validators } from 'utils/form-utils';
// import { Loader } from 'components/Loader';

interface FormValues {
  title: string;
  url: string;
}

const validationSchema = () =>
  Yup.object()
    .shape({
      title: validators.title,
      url: validators.url,
    })
    .required();

const initialValues = {
  title: '',
  url: '',
};

interface Props {
  error?: string;
  onCancel: () => void;
  onSend?: (params: FormValues) => void;
}

export const QuicklinkPopup: React.FC<Props> = ({ onSend, onCancel }) => {
  const formik = useFormik<FormValues>({
    enableReinitialize: true,
    initialValues: {
      ...initialValues,
    },
    onSubmit: () => undefined,
    isInitialValid: false,
    validate: validate<FormValues>(validationSchema),
  });

  const { values, errors, touched, handleChange, handleBlur, handleSubmit, isValid } = formik;

  return (
    <Card className={styles.popup}>
      <CardHeader>
        <h3>Create Quicklink</h3>
        <p>Quicklinks allow you to easily access a url relavant to the project.</p>
      </CardHeader>
      <Form onSubmit={handleSubmit} noValidate name="simpleForm">
        <CardBody className="mh-371">
          <FormGroup>
            <label htmlFor="title">Quicklink Title</label>
            <Input
              type="text"
              name="title"
              id="title"
              placeholder="What would you like to call it?"
              autoComplete="title"
              valid={touched.title && !errors.title}
              invalid={touched.title && !!errors.title}
              required
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.title}
            />
            <FormFeedback>{errors.title}</FormFeedback>
          </FormGroup>
          <FormGroup>
            <label htmlFor="url">Quicklink URL</label>
            <Input
              type="url"
              name="url"
              id="url"
              placeholder="Please paste the entire url here"
              autoComplete="url"
              valid={touched.url && !errors.url}
              invalid={touched.url && !!errors.url}
              required
              onChange={handleChange}
              onBlur={handleBlur}
              value={values.url}
            />
            <FormFeedback>{errors.url}</FormFeedback>
          </FormGroup>
          {/* <div className={styles.quicklink_placeholder}>
            <div className="fadeIn loader-inline">
              <Loader />
              <span className="loader-title">Loading preview..</span>
            </div>
          </div> */}
        </CardBody>
        <CardFooter className="text-right">
          <Button type="button" color="outline-secondary" onClick={onCancel}>
            Cancel
          </Button>
          <Button type="button" color="primary" className="ml-10" disabled={!isValid} onClick={handleSubmit}>
            Create Quicklink
          </Button>
        </CardFooter>
      </Form>
    </Card>
  );
};
