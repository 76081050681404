import { observable } from 'mobx';
import { ServicesStore } from 'store/services/services.store';
import {
  ProjectRequest,
  getProjectRequestInputDefaults,
  SiloSkill,
  SiloService,
  SiloSearchInput,
  ProjectRequestParams,
} from '../../types/request.types';
import { DomainsStore } from './domains.store';
export class RequestProjectDomain {
  @observable private _projectRequestInput: ProjectRequestParams = getProjectRequestInputDefaults();

  constructor(private domainStore: DomainsStore, private services: ServicesStore) {}

  get projectRequest(): ProjectRequestParams {
    return this._projectRequestInput;
  }

  set projectRequest(projectRequest: ProjectRequestParams) {
    this._projectRequestInput = projectRequest;
  }

  searchSiloServices(input: SiloSearchInput): Promise<SiloService[]> {
    return this.services.requestService.searchSiloServices(input);
  }

  searchSiloSkills(input: SiloSearchInput): Promise<SiloSkill[]> {
    return this.services.requestService.searchSiloSkills(input);
  }

  async saveProjectRequest(): Promise<ProjectRequest> {
    const projectRequest = await this.services.requestService.addProjectRequest(this._projectRequestInput);
    const { createdAt, ...request } = projectRequest;
    this._projectRequestInput = request;
    return projectRequest;
  }

  cleanProjectRequest() {
    this._projectRequestInput = getProjectRequestInputDefaults();
  }
}
