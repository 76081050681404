import React from 'react';
import * as history from 'history';
import { Router } from 'react-router-dom';
import { AppRouter } from './routing';
import { RouterStore, syncHistoryWithStore } from 'mobx-react-router';
import { AppStore } from './store';
import { AppStoreProvider } from './store/app-store.hook';
import { ToastProvider } from 'react-toast-notifications';
import { ToastNotifications } from 'components/ToastNotifications';
import { ErrorBoundary } from 'react-error-boundary';

const browserHistory = history.createBrowserHistory();
const routerStore = new RouterStore();
const syncHistory = syncHistoryWithStore(browserHistory, routerStore);
const appStore = new AppStore(routerStore);
const createAppStore = () => appStore;

function ErrorFallback({ error, resetErrorBoundary }: any) {
  return (
    <div role="alert">
      <p>Something went wrong:</p>
      <pre>{error.message}</pre>
      <button onClick={resetErrorBoundary}>Try again</button>
    </div>
  );
}

export const App = () => {
  const [isInitialized, setInitialized] = React.useState(false);

  React.useEffect(() => {
    (async function () {
      await appStore.authStore.initialize();
      setInitialized(true);
    })();
  }, []);

  return isInitialized ? (
    <Router history={syncHistory}>
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <AppStoreProvider createAppStore={createAppStore}>
          <ToastProvider placement="bottom-right" autoDismissTimeout={2000}>
            <ToastNotifications />
            <AppRouter />
          </ToastProvider>
        </AppStoreProvider>
      </ErrorBoundary>
    </Router>
  ) : null;
};
