import moment from 'moment-timezone';
import _, { Dictionary } from 'lodash';
import { abbreviations } from './zone-abbreviations';

interface TimeZone {
  region: string;
  offset: number;
}

moment.fn.zoneName = function () {
  var abbr = this.zoneAbbr();
  return abbreviations[abbr] || abbr;
};

const zones: TimeZone[] = moment.tz.names().map((v) => {
  const offset = Math.round(moment.tz(v).utcOffset() / 60);
  return {
    region: v.split('/')[0],
    offset,
  };
});

const uniqZones = _.uniqBy(zones, ({ region, offset }) => `${region}${offset}`);

export const REGIONS = ['US', 'Europe', 'Asia', 'Africa', 'Australia'];
export const groupByRegion: Dictionary<TimeZone[]> = _.groupBy(uniqZones, 'region');

export const getUtcDisplay = (offset: number) => {
  const offsetSign = offset > 0 ? '+' : offset < 0 ? '-' : '';
  const offsetPad = ('0' + Math.abs(offset)).slice(-2);
  return `${offsetSign}${offsetPad}:00`;
};

export const getTimezoneName = (offset: number, region: string) => {
  const byRegion = moment.tz.names().filter((name) => name.split('/')[0] === region);
  return _.find(byRegion, (timezoneName) => {
    return offset * 60 === moment.tz(timezoneName).utcOffset();
  })?.replace('_', ' ')?.split('/')[1] || '';
};

export const getDefaultTimeZone = () => {
  const timeZone = moment.tz.guess();
  return {
    region: timeZone.split('/')[0],
    offset: Math.round(moment.tz(timeZone).utcOffset() / 60),
  };
};
