import { ServicesStore } from 'store/services/services.store';
import { RequestDemoDomain } from './request-demo.domain';
import { RequestProjectDomain } from './request-project.domain';
import { ReviewsDomain } from './reviews.domain';

export class DomainsStore {
  public requestDemo: RequestDemoDomain;
  public requestProject: RequestProjectDomain;
  public reviews: ReviewsDomain;

  constructor(servicesStore: ServicesStore) {
    this.requestDemo = new RequestDemoDomain(this, servicesStore);
    this.requestProject = new RequestProjectDomain(this, servicesStore);
    this.reviews = new ReviewsDomain(this, servicesStore);
  }
}
