import React from 'react';
import { ReactComponent as Logo } from '../../../svg/mt-logo-dark.svg';
import { CheckEmailIcon } from '../../SvgIcons/CheckEmail';
import { Button } from 'reactstrap';

interface Props {
  email: string;
  onResend: () => void;
}

export const StartProject6: React.FC<Props> = ({ email, onResend }) => {
  return (
    <>
      <div className="auth-box d-flex flex-column">
        <div className="auth-headline mb-0">
          <strong className="auth-logo">
            <Logo />
          </strong>
        </div>
        <div className="auth-message text-center">
          <CheckEmailIcon />
          <h1 className="mt-20">Thanks! A MindTrust business consultant will be in touch soon</h1>
          <p>
            We’ve sent a confirmation email to
            <br /> {email}
          </p>
        </div>
      </div>
      <p className="help-text pt-48">
        Didn’t receive an email?{' '}
        <Button color="link" onClick={onResend}>
          Click to resend
        </Button>
      </p>
    </>
  );
};
