import React from 'react';
import { LoginInForm } from '../../components/Auth/LoginForm';
import { useAppStore } from '../../store/app-store.hook';
import { observer } from 'mobx-react-lite';
import { Helmet } from 'react-helmet';
import { Redirect } from 'react-router-dom';

export const LoginPage: React.FC = observer(() => {
  const { authStore } = useAppStore();

  if (authStore.me) return <Redirect to="/" />;

  return (
    <>
      <Helmet>
        <title>Login | MindTrust</title>
      </Helmet>
      <LoginInForm onLogin={authStore.login} error={authStore.error} />
    </>
  );
});
