import * as requestQueries from './request-queries';
import { ServicesStore } from './services.store';
import {
  AddProjectRequestMutation,
  AddProjectRequestMutationVariables,
} from 'types/gql-generated/AddProjectRequestMutation';
import { SearchSiloServicesQuery, SearchSiloServicesQueryVariables } from 'types/gql-generated/SearchSiloServicesQuery';
import { SearchSiloSkillsQueryVariables, SearchSiloSkillsQuery } from 'types/gql-generated/SearchSiloSkillsQuery';
import { SyncSiloMutation } from 'types/gql-generated/SyncSiloMutation';
import { BookMeetingInfoQuery } from 'types/gql-generated/BookMeetingInfoQuery';
import { BookMeetingMutationVariables, BookMeetingMutation } from 'types/gql-generated/BookMeetingMutation';

export class RequestService {
  constructor(private servicesStore: ServicesStore) {}

  addProjectRequest = async (
    input: AddProjectRequestMutationVariables['input'],
  ): Promise<AddProjectRequestMutation['addProjectRequest']> =>
    (
      await this.servicesStore.qglConnector.mutate<AddProjectRequestMutation>({
        mutation: requestQueries.ADD_PROJECT_REQUEST_MUTATION,
        variables: { input },
      })
    ).addProjectRequest;

  searchSiloServices = async (
    input: SearchSiloServicesQueryVariables['input'],
  ): Promise<SearchSiloServicesQuery['searchSiloServices']> =>
    (
      await this.servicesStore.qglConnector.query<SearchSiloServicesQuery, SearchSiloServicesQueryVariables>({
        query: requestQueries.SEARCH_SILO_SERVICES_QUERY,
        variables: { input },
      })
    ).searchSiloServices;

  searchSiloSkills = async (
    input: SearchSiloSkillsQueryVariables['input'],
  ): Promise<SearchSiloSkillsQuery['searchSiloSkills']> =>
    (
      await this.servicesStore.qglConnector.query<SearchSiloSkillsQuery, SearchSiloSkillsQueryVariables>({
        query: requestQueries.SEARCH_SILO_SKILLS_QUERY,
        variables: { input },
      })
    ).searchSiloSkills;

  syncSilo = async (): Promise<SyncSiloMutation['syncSilo']> =>
    (
      await this.servicesStore.qglConnector.mutate<SyncSiloMutation>({
        mutation: requestQueries.SYNC_SILO_MUTATION,
      })
    ).syncSilo;

  bookMeetingInfo = async (): Promise<BookMeetingInfoQuery['bookMeetingInfo']> =>
    (
      await this.servicesStore.qglConnector.query<BookMeetingInfoQuery, {}>({
        query: requestQueries.BOOK_MEETING_INFO_QUERY,
      })
    ).bookMeetingInfo;

  bookMeeting = async (input: BookMeetingMutationVariables['input']): Promise<BookMeetingMutation['bookMeeting']> =>
    (
      await this.servicesStore.qglConnector.mutate<BookMeetingMutation>({
        mutation: requestQueries.BOOK_MEETING_MUTATION,
        variables: { input },
      })
    ).bookMeeting;
}
