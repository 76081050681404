import React from 'react';
import { Helmet } from 'react-helmet';
import { observer } from 'mobx-react-lite';
import { useAppStore } from 'store/app-store.hook';
import { StartDemo1 } from 'components/ProjectRequest/StartDemo1';

export const DemoRequest1Page = observer(() => {
  const { demoRequestPage } = useAppStore().ui;
  return (
    <>
      <Helmet>
        <title>Request Project</title>
      </Helmet>
      <StartDemo1
        requestStep1={demoRequestPage.projectRequest}
        onNext={demoRequestPage.process1Step}
      />
    </>
  );
});
