import React from 'react';
import { Row, Col, Modal } from 'reactstrap';
import { ProjectMenu } from '../../components/ProjectMenu';
import { Helmet } from 'react-helmet';

import { useAppStore } from 'store/app-store.hook';

import { PageHeader } from 'components/PageHeader';
import { ProjectTitle } from 'components/ProjectTitle';
import { ProjectNavigtaion } from './ProjectNavigation';
import { useParams } from 'react-router-dom';
import { QuicklinkCreate } from 'components/Quicklink/QuicklinkCreate';
import { QuicklinkPopup } from 'components/Quicklink/QuicklinkPopup';

export const ProjectQuicklinksPage = () => {
  const { projectQuickLinksStore } = useAppStore();

  const [showPopup, setShowPopup] = React.useState(false);

  const { id } = useParams<{ id: string }>();

  return (
    <div>
      <Helmet>
        <title>Construction SaaS | Quicklinks</title>
      </Helmet>
      <PageHeader
        title={<ProjectTitle {...projectQuickLinksStore.title} />}
        navigation={<ProjectNavigtaion id={id} />}
        menu={<ProjectMenu />}
      />
      <Row>
        <Col>
          {/* <QuicklinkList links={links} /> */}
          <div className="mh-custom text-center d-flex align-items-center justify-content-center pb-100">
            <QuicklinkCreate onCreate={() => setShowPopup(true)} />
          </div>
        </Col>
      </Row>
      <Modal isOpen={showPopup} toggle={() => setShowPopup(!showPopup)}>
        <QuicklinkPopup onCancel={() => setShowPopup(false)} />
      </Modal>
    </div>
  );
};
