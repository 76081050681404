import React from 'react';
import { useParams } from 'react-router-dom';
import { Row, Col } from 'reactstrap';
import { ProjectTitle } from '../../components/ProjectTitle';
import { ProjectMenu } from '../../components/ProjectMenu';
import { ProjectTeamTable } from '../../components/ProjectTable';
import { useAppStore } from 'store/app-store.hook';
import { observer } from 'mobx-react-lite';
import { Helmet } from 'react-helmet';
import { BudgetInfo } from 'components/BudgetInfo';
import moment from 'moment';
import { TableToolbar } from 'components/TableToolbar';
import { ProjectNavigtaion } from './ProjectNavigation';
import { PageHeader } from '../../components/PageHeader/PageHeader';

export const ProjectOverviewPage: React.FC = observer(() => {
  const { projectOverviewStore, timelineStore } = useAppStore();

  const { id } = useParams<{ id: string }>();

  React.useEffect(() => {
    projectOverviewStore.loadProject({
      id,
      from: moment(timelineStore.period.start).format('YYYY-MM-DD'),
      to: moment(timelineStore.period.end).format('YYYY-MM-DD'),
    });
  }, [id, projectOverviewStore, timelineStore.period]);

  return (
    <div>
      <Helmet>
        <title>{projectOverviewStore.clientName} | Overview</title>
      </Helmet>
      <PageHeader
        title={<ProjectTitle {...projectOverviewStore.title} />}
        navigation={<ProjectNavigtaion id={id} />}
        menu={<ProjectMenu />}
        toolbar={<TableToolbar {...timelineStore.toolbar} />}
      />
      <Row>
        <Col className="mb-24 mb-md-0" lg={{ size: 4 }}>
          <BudgetInfo {...projectOverviewStore.budgetInfo} />
        </Col>
        <Col lg={{ size: 8 }}>
          <div className="panel h-100">
            <ProjectTeamTable {...projectOverviewStore.projectTeam} />
          </div>
        </Col>
      </Row>
    </div>
  );
});
