import React from 'react';
import { Helmet } from 'react-helmet';
import { StartProject2ResourceBased } from 'components/ProjectRequest';
import { observer } from 'mobx-react-lite';
import { useAppStore } from 'store/app-store.hook';

export const ProjectRequest2SkillsPage = observer(() => {
  const { projectRequestPage } = useAppStore().ui;

  React.useEffect(() => {
    projectRequestPage.searchSkills();
  }, [projectRequestPage]);

  return (
    <>
      <Helmet>
        <title>Request Project</title>
      </Helmet>
      <StartProject2ResourceBased
        requestStep2={projectRequestPage.projectRequest}
        skills={projectRequestPage.skills}
        loadMoreSkills={projectRequestPage.searchSkills}
        onNext={projectRequestPage.process2SkillsStep}
        goBack={projectRequestPage.goBack}
      />
    </>
  );
});
