import React from 'react';
import classNames from 'classnames';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { Button, Col, CustomInput, Form, FormFeedback, FormGroup, Input, Row } from 'reactstrap';
import { NavLink } from 'react-router-dom';
import { validate } from '../../../utils/form-utils';
import { ReactComponent as Logo } from '../../../svg/mt-logo-dark.svg';
import { SingleDatePicker } from 'react-dates';
import { Moment } from 'moment';
import { ChangeEventHandler } from 'react';
import moment from 'moment';
import IsISO8601 from 'validator/lib/isISO8601';
import { requestDemoRoutes } from 'routing';
import { Step5ProjectRequest } from 'types/request.types';

const validationSchema = () =>
  Yup.object()
    .shape({
      whenToStart: Yup.string()
        .test('whenToStart', 'Please provide date in YYYY-MM-DD format', (v: string | null) =>
          v ? IsISO8601(v) : true,
        )
        .nullable(),
      additionalDetails: Yup.string().nullable(),
    })
    .required();

interface Props {
  requestStep5: Step5ProjectRequest;
  onSubmit: (params: Step5ProjectRequest) => void;
  goBack: () => void;
}

enum When {
  asap,
  selectDate,
  notSure,
}

export const StartProject5: React.FC<Props> = ({ onSubmit, goBack, requestStep5 }) => {
  const formik = useFormik<Step5ProjectRequest>({
    enableReinitialize: true,
    initialValues: {
      ...requestStep5,
    },
    onSubmit: onSubmit,
    validate: validate<Step5ProjectRequest>(validationSchema),
  });
  const { values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue } = formik;
  const [date, setDate] = React.useState<Moment | null>(values.whenToStart ? moment(values.whenToStart) : null);
  const [focus, setFocus] = React.useState<{ focused: boolean }>({ focused: false });
  const [when, setWhen] = React.useState(When.selectDate);
  const showDatePicker = React.useMemo(() => when === When.selectDate, [when]);

  const onCheck = React.useCallback(
    (key: When): ChangeEventHandler<{ value: string }> => () => {
      setWhen(key);
      if (key === When.asap) {
        setFieldValue('whenToStart', moment().format('YYYY-MM-DD'), true);
        return;
      }
      if (key === When.selectDate) {
        setFieldValue('whenToStart', null, true);
        return;
      }
      if (key === When.notSure) {
        setFieldValue('whenToStart', null, true);
        return;
      }
    },
    [setFieldValue],
  );

  const onDateChange = (date: Moment | null) => {
    setDate(date);
    setFieldValue('whenToStart', moment(date).format('YYYY-MM-DD'), true);
  };

  return (
    <Form onSubmit={handleSubmit}>
      <div className="auth-box">
        <div className="auth-headline">
          <strong className="auth-logo">
            <Logo />
          </strong>
          <h1>When would you like to start?</h1>
        </div>
        <FormGroup className="custom-radio-buttons no-icons">
          <Row className="gap-cards">
            <Col xs={4}>
              <CustomInput
                type="radio"
                id="asap"
                label="ASAP"
                name="start"
                required
                checked={when === When.asap}
                onChange={onCheck(When.asap)}
                onBlur={handleBlur}
              />
            </Col>
            <Col xs={4}>
              <CustomInput
                type="radio"
                id="start_pickDate"
                label="Pick a date"
                name="start"
                required
                checked={when === When.selectDate}
                onChange={onCheck(When.selectDate)}
                onBlur={handleBlur}
              />
            </Col>
            <Col xs={4}>
              <CustomInput
                type="radio"
                id="unsure"
                label="Unsure"
                name="start"
                required
                checked={when === When.notSure}
                onChange={onCheck(When.notSure)}
                onBlur={handleBlur}
              />
            </Col>
          </Row>
        </FormGroup>
        {showDatePicker && (
          <FormGroup
            className={classNames(
              'form-group-addon',
              { 'input-active': values.whenToStart },
              { 'input-invalid': touched.whenToStart && !!errors.whenToStart },
              { 'input-touched': touched.whenToStart },
              { 'input-valid': touched.whenToStart && !errors.whenToStart },
            )}
          >
            <label htmlFor="pickDate">Pick a date</label>
            <SingleDatePicker
              id="pickDate"
              date={date}
              readOnly
              onDateChange={onDateChange}
              focused={focus.focused}
              onFocusChange={setFocus}
              numberOfMonths={1}
              placeholder="Select start date"
              hideKeyboardShortcutsPanel
              displayFormat="MM-DD-YYYY"
              transitionDuration={700}
            />
            <FormFeedback>{errors.whenToStart}</FormFeedback>
          </FormGroup>
        )}
        <FormGroup
          className={classNames(
            'form-group-addon',
            { 'input-active': values.additionalDetails },
            { 'input-invalid': touched.additionalDetails && !!errors.additionalDetails },
            { 'input-touched': touched.additionalDetails },
            { 'input-valid': touched.additionalDetails && !errors.additionalDetails },
          )}
        >
          <label htmlFor="additionalDetails">Additional details</label>
          <Input
            className="form-control"
            type="textarea"
            name="additionalDetails"
            id="additionalDetails"
            placeholder="Is there any other information that you’d like to share?"
            valid={touched.additionalDetails && !errors.additionalDetails}
            invalid={touched.additionalDetails && !!errors.additionalDetails}
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.additionalDetails || ''}
          />
          <FormFeedback>{errors.additionalDetails}</FormFeedback>
        </FormGroup>
      </div>
      <div className="auth-actions">
        <FormGroup>
          <Row className="gap-xs">
            <Col xs={6}>
              <Button className="btn-block c-gray" color="default" onClick={goBack}>
                <i className="btn-icon-back" />
                Back
              </Button>
            </Col>
            <Col xs={6}>
              <Button
                className="btn-block"
                type="submit"
                color="primary"
                disabled={!values.whenToStart && !date && when !== When.notSure}
                onClick={handleSubmit}
              >
                Submit
              </Button>
            </Col>
          </Row>
        </FormGroup>
      </div>
      <p className="help-text">
        Need help? <NavLink to={requestDemoRoutes.request(2)}>Speak with a Sales Consultant.</NavLink>
      </p>
    </Form>
  );
};
