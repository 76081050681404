import React from 'react';
import { Switch, Route, Redirect, useParams, useLocation } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { requestDemoRoutes } from '../../routing';
import { DemoRequest1Page } from './DemoRequest1Page';
import { DemoRequest2Page } from './DemoRequest2Page';
import { useAppStore } from 'store/app-store.hook';
import { DemoRequest3Page } from './DemoRequest3Page';
import { AuthLayout } from 'components/containers/AuthLayout';
import { Promo } from 'components/Promo';

export const DemoRequestRoutes: React.FC = observer(() => {
  const { step } = useParams<{ step: string }>();
  const { search } = useLocation();
  const { demoRequestPage, projectRequestPage } = useAppStore().ui;

  const currentStep = Math.max(demoRequestPage.currentStep, projectRequestPage.currentStep);
  if (+step > currentStep) {
    return <Redirect to={`${requestDemoRoutes.request(currentStep)}${search}`} />;
  }
  return (
    <AuthLayout
      progress={(100 / 3) * +step}
      isLoading={demoRequestPage.isLoading}
      leftSide={
        <Switch>
          <Route
            exact
            path={requestDemoRoutes.main}
            component={() => {
              const { search } = useLocation();
              return <Redirect to={`${requestDemoRoutes.request(1)}${search}`} />;
            }}
          />
          <Route exact path={requestDemoRoutes.request(1)} component={DemoRequest1Page} />
          <Route exact path={requestDemoRoutes.request(2)} component={DemoRequest2Page} />
          <Route exact path={requestDemoRoutes.request(3)} component={DemoRequest3Page} />
        </Switch>
      }
      rightSide={<Promo {...demoRequestPage.getReview()} />}
    />
  );
});
