import { UserTokens } from 'types/auth-types';

enum StorageKeys {
  user_tokens = 'user_tokens',
  user_info = 'user_info',
  project_request = 'project_request',
}

export const saveTokenToLocalStorage = (userTokens: UserTokens): UserTokens => {
  localStorage.setItem(StorageKeys.user_tokens, JSON.stringify(userTokens));
  return userTokens;
};

export const readTokenToLocalStorage = (): UserTokens | null => {
  const tokensStr = localStorage.getItem(StorageKeys.user_tokens);
  return tokensStr && JSON.parse(tokensStr);
};

export const clearLocalStorage = () => {
  localStorage.removeItem(StorageKeys.user_tokens);
  localStorage.removeItem(StorageKeys.user_info);
};
