import React from 'react';
import { Switch, Route, Redirect, useParams, useLocation } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { requestProjectRoutes } from '../../routing';
import { ProjectRequest1Page } from './ProjectRequest1Page';
import { useAppStore } from 'store/app-store.hook';
import { ProjectRequest2ServicesPage } from './ProjectRequest2ServicesPage';
import { ProjectRequest3Page } from './ProjectRequest3Page';
import { ProjectRequest4Page } from './ProjectRequest4Page';
import { ProjectRequest5Page } from './ProjectRequest5Page';
import { ProjectRequest6Page } from './ProjectRequest6Page';
import { ProjectRequest2SkillsPage } from './ProjectRequest2SkillsPage';
import { AuthLayout } from 'components/containers/AuthLayout';
import { Promo } from 'components/Promo';

export const ProjectRequestRoutes: React.FC = observer(() => {
  const { step } = useParams<{ step: string }>();
  const { search } = useLocation();

  const { projectRequestPage } = useAppStore().ui;

  const { currentStep } = projectRequestPage;
  if (+step > currentStep) {
    return <Redirect to={`${requestProjectRoutes.request(currentStep)}${search}`} />;
  }
  return (
    <AuthLayout
      progress={(100 / 6) * +step}
      isLoading={projectRequestPage.isLoading}
      leftSide={
        <Switch>
          <Route
            exact
            path={requestProjectRoutes.main}
            component={() => {
              const { search } = useLocation();
              return <Redirect to={`${requestProjectRoutes.request(1)}${search}`} />;
            }}
          />
          <Route exact path={requestProjectRoutes.request(1)} component={ProjectRequest1Page} />
          <Route exact path={requestProjectRoutes.request(2)} component={ProjectRequest2ServicesPage} />
          <Route exact path={`${requestProjectRoutes.request(2)}/resources`} component={ProjectRequest2SkillsPage} />
          <Route exact path={requestProjectRoutes.request(3)} component={ProjectRequest3Page} />
          <Route exact path={requestProjectRoutes.request(4)} component={ProjectRequest4Page} />
          <Route exact path={requestProjectRoutes.request(5)} component={ProjectRequest5Page} />
          <Route exact path={requestProjectRoutes.request(6)} component={ProjectRequest6Page} />
        </Switch>
      }
      rightSide={<Promo {...projectRequestPage.getReview()} />}
    />
  );
});
