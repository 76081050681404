import React from 'react';
import ReactTooltip from 'react-tooltip';
import { Button } from 'reactstrap';
import { Link } from 'react-router-dom';
import { Pie } from 'components/Pie';

interface Props {
  budget: string;
  bonus: string;
  teamTime: string;
  billableExpenses: string;
  remainingBudget: string;
  getMoreHours: () => void;
}

export const BudgetInfo: React.FC<Props> = ({
  budget,
  bonus,
  teamTime,
  billableExpenses,
  remainingBudget,
  getMoreHours,
}) => {
  return (
    <div className="panel panel--paddings h-100">
      <Pie value={15} title={'days left in cycle'} />
      <dl className="d-flex justify-content-between" style={{ borderBottom: '1px solid #d4dee5' }}>
        <dt style={{ display: '1px solid #d4dee5' }}>Budget</dt>
        <dd>{budget}</dd>
      </dl>
      <dl className="d-flex justify-content-between" style={{ borderBottom: '1px solid #d4dee5' }}>
        <dt>Bonus</dt>
        <dd>{bonus}</dd>
      </dl>
      <dl className="d-flex justify-content-between" style={{ borderBottom: '1px solid #d4dee5' }}>
        <dt>
          Team Time
          <span
            className="note-tooltip"
            data-place="right"
            data-multiline="true"
            data-text-color="#151515"
            data-border="true"
            data-border-color="#D5DFE6"
            data-tip="A team member has an unsubmitted timesheet for the<br> active period and that the numbers may change."
          >
            !
          </span>
          <ReactTooltip type="light" className="tooltip" />
        </dt>
        <dd>{teamTime}</dd>
      </dl>
      <dl className="d-flex justify-content-between" style={{ borderBottom: '1px solid #d4dee5' }}>
        <dt>Billable Expenses</dt>
        <dd>{billableExpenses}</dd>
      </dl>
      <dl className="d-flex justify-content-between" style={{ borderBottom: '1px solid #d4dee5', color: '#2e353a' }}>
        <dt>Remaining Budget</dt>
        <dd>{remainingBudget}</dd>
      </dl>
      <div className="get_more mtb-25">
        <Button className="mb-7" color="tertiary w-100" onClick={getMoreHours}>
          Get more hours <i className="icon-arrow-right"></i>
        </Button>
        <p className="fs-24">
          Note: Access to manage billing account is role specific. Please contact your Compny Administrator or Billing
          Contact.
        </p>
        <div className="d-flex justify-content-between">
          <Link to="#" className="simple-link">
            Explore Unlimited
          </Link>
          <Link to="#" className="simple-link">
            Manage Alert Settings
          </Link>
        </div>
      </div>
    </div>
  );
};
