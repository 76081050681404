import React from 'react';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { Button, Col, CustomInput, Form, FormGroup, Row } from 'reactstrap';
import { Link, NavLink, useLocation } from 'react-router-dom';
import { validate } from '../../../utils/form-utils';
import { ReactComponent as Logo } from '../../../svg/mt-logo-dark.svg';
import { ProjectRequestType } from 'types/gql-generated';
import { ChangeEventHandler } from 'react';
import { requestDemoRoutes, requestProjectRoutes } from 'routing';
import { Step3ProjectRequest } from 'types/request.types';

const validationSchema = () =>
  Yup.object()
    .shape({
      type: Yup.mixed()
        .oneOf([
          ProjectRequestType.EXISTING_PROJECT,
          ProjectRequestType.LEARN_ABOUT,
          ProjectRequestType.NEW_PROJECT,
          ProjectRequestType.ONGOING_ASSISTANCE,
        ])
        .nullable()
        .notRequired(),
    })
    .required();

const initialValues = {
  type: ProjectRequestType.NEW_PROJECT,
};

interface Props {
  requestStep3: Step3ProjectRequest;
  silo?: string;
  onNext: (params: Step3ProjectRequest) => void;
  goBack: () => void;
}

export const StartProject3: React.FC<Props> = ({ requestStep3, silo, onNext, goBack }) => {
  const { search } = useLocation();

  const formik = useFormik<Step3ProjectRequest>({
    enableReinitialize: true,
    initialValues: { ...initialValues, ...requestStep3 },
    onSubmit: onNext,
    validate: validate<Step3ProjectRequest>(validationSchema),
  });
  const { values, handleBlur, handleSubmit, setFieldValue } = formik;

  const onCheck = React.useCallback(
    (key: string): ChangeEventHandler<{ value: string }> => () => {
      setFieldValue('type', key, true);
    },
    [setFieldValue],
  );

  return (
    <Form onSubmit={handleSubmit}>
      <div className="auth-box">
        <div className="auth-headline">
          <strong className="auth-logo">
            <Logo />
          </strong>
        </div>
        {silo ? <h1>How can we help you with {silo}?</h1> : <h1>What type of project is it?</h1>}
        {silo && (
          <p>
            You got here from a page on our website about {silo}. If you're looking for something else, please select
            your desired type of <Link to={`${requestProjectRoutes.request(2)}${search}`}>project type</Link> or{' '}
            <Link to={`${requestProjectRoutes.request(2)}/resources${search}`}>specific team members</Link> instead.
          </p>
        )}
        <FormGroup className="checkbox-list">
          <CustomInput
            type="radio"
            id="radio1"
            label="New idea or project"
            name={ProjectRequestType.NEW_PROJECT}
            required
            onChange={onCheck(ProjectRequestType.NEW_PROJECT)}
            onBlur={handleBlur}
            checked={ProjectRequestType.NEW_PROJECT === values.type}
          />
          <CustomInput
            type="radio"
            id="radio2"
            label="Existing project that needs more team resources"
            name={ProjectRequestType.EXISTING_PROJECT}
            required
            onChange={onCheck(ProjectRequestType.EXISTING_PROJECT)}
            onBlur={handleBlur}
            checked={ProjectRequestType.EXISTING_PROJECT === values.type}
          />
          <CustomInput
            type="radio"
            id="radio3"
            label="Ongoing assistance or consultation"
            name={ProjectRequestType.ONGOING_ASSISTANCE}
            required
            onChange={onCheck(ProjectRequestType.ONGOING_ASSISTANCE)}
            onBlur={handleBlur}
            checked={ProjectRequestType.ONGOING_ASSISTANCE === values.type}
          />
          <CustomInput
            type="radio"
            id="radio4"
            label="None of the above, I’m just looking to learn more about MindTrust"
            name={ProjectRequestType.LEARN_ABOUT}
            required
            onChange={onCheck(ProjectRequestType.LEARN_ABOUT)}
            onBlur={handleBlur}
            checked={ProjectRequestType.LEARN_ABOUT === values.type}
          />
        </FormGroup>
      </div>
      <div className="auth-actions">
        <FormGroup>
          <Row className="gap-xs">
            <Col xs={6}>
              <Button className="btn-block c-gray" color="default" onClick={goBack}>
                <i className="btn-icon-back" />
                Back
              </Button>
            </Col>
            <Col xs={6}>
              <Button className="btn-block" type="submit" color="primary" onClick={handleSubmit}>
                Next<i className="btn-icon-next"></i>
              </Button>
            </Col>
          </Row>
        </FormGroup>
      </div>
      <p className="help-text">
        Need help? <NavLink to={requestDemoRoutes.request(2)}>Speak with a Sales Consultant.</NavLink>
      </p>
    </Form>
  );
};
