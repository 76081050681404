import React from 'react';
import { Helmet } from 'react-helmet';
import { observer } from 'mobx-react-lite';
import { useAppStore } from 'store/app-store.hook';
import { StartDemo3 } from 'components/ProjectRequest/StartDemo3';

export const DemoRequest3Page = observer(() => {
  const { demoRequestPage } = useAppStore().ui;
  return (
    <>
      <Helmet>
        <title>Request Project</title>
      </Helmet>
      <StartDemo3 onResend={demoRequestPage.resend} bookedMeeting={demoRequestPage.bookedMeeting} />
    </>
  );
});
