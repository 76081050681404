export const nonExistProjectOptions = [
  {
    label: '$0 - $5,000 USD',
    value: {
      budgetFromUsd: 0,
      budgetToUsd: 5000,
    },
  },
  {
    label: '$5,000 - $25,000 USD',
    value: {
      budgetFromUsd: 5000,
      budgetToUsd: 25000,
    },
  },
  {
    label: '$25,000 - $50,000 USD',
    value: {
      budgetFromUsd: 25000,
      budgetToUsd: 50000,
    },
  },
  {
    label: '$50,000 - $100,000 USD',
    value: {
      budgetFromUsd: 50000,
      budgetToUsd: 100000,
    },
  },
  {
    label: '$100,000 - $250,000 USD',
    value: {
      budgetFromUsd: 100000,
      budgetToUsd: 250000,
    },
  },
  {
    label: '$250,000 - $500,000 USD',
    value: {
      budgetFromUsd: 250000,
      budgetToUsd: 500000,
    },
  },
  {
    label: '$500,000+',
    value: {
      budgetFromUsd: 500000,
      budgetToUsd: null,
    },
  },
];

export const existingProjectOptions = [
  {
    label: '$0 - $2,500 USD per month',
    value: {
      budgetFromUsd: 0,
      budgetToUsd: 2500,
    },
  },
  {
    label: '$2,500 - $10,000 USD per month',
    value: {
      budgetFromUsd: 2500,
      budgetToUsd: 10000,
    },
  },
  {
    label: '$10,000 - $25,000 USD per month',
    value: {
      budgetFromUsd: 10000,
      budgetToUsd: 25000,
    },
  },
  {
    label: '$25,000 - $50,000 USD per month',
    value: {
      budgetFromUsd: 25000,
      budgetToUsd: 50000,
    },
  },
  {
    label: '$50,000 - $100,000 USD per month',
    value: {
      budgetFromUsd: 50000,
      budgetToUsd: 100000,
    },
  },
  {
    label: '$100,000+ per month',
    value: {
      budgetFromUsd: 100000,
      budgetToUsd: null,
    },
  },
];
