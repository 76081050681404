import gql from 'graphql-tag';

export const PROJECTS = gql`
  query ProjectsQL($input: SearchInput!) {
    projects(input: $input) {
      offset
      limit
      total
      nodes {
        costTaasHours
        costUsd
        hours
        usedTaasHours
        usedUsd
        budgetTaasHours
        budgetUsd
        fulfilmentTaasHours
        fulfilmentUsd
        remainingTaasHours
        remainingUsd
        id
        name
        clientId
        clientName
        agencyId
        agencyName
        type
        status
        billableExpensesUsd
      }
    }
  }
`;

export const PROJECT_OVERVIEW = gql`
  query ProjectOverviewQL($input: ProjectOverviewInput!) {
    projectOverview(input: $input) {
      project {
        id
        name
        clientId
        clientName
        agencyId
        agencyName
      }
      team {
        id
        costTaasHours
        costUsd
        hours
        usedTaasHours
        usedUsd
        budgetTaasHours
        budgetUsd
        fulfilmentTaasHours
        fulfilmentUsd
        remainingTaasHours
        remainingUsd
        email
        firstName
        lastName
        teamRole
        department
        avatarUrl
      }
    }
  }
`;

export const PROJECT_DETAILS = gql`
  query ProjectDetailsQL($input: ProjectDetailsInput!) {
    projectDetails(input: $input) {
      project {
        id
        name
        clientId
        clientName
        agencyId
        agencyName
      }
      team {
        id
        email
        firstName
        lastName
        teamRole
        department
        avatarUrl
      }
      expenses {
        expensesCategoryName
        billableExpensesUsd
      }
      details {
        costTaasHours
        costUsd
        hours
        usedTaasHours
        usedUsd
        period
        startingBalance
        netCashMovement
        endBalance
        credits
        team {
          costTaasHours
          costUsd
          hours
          usedTaasHours
          usedUsd
          projectId
          userId
          entriesCount
          period
        }
        billableExpensesUsd
        expenses {
          period
          expensesCategoryName
          billableExpensesUsd
        }
      }
    }
  }
`;

// export const PROJECT_QUICKLINKS = gql`
//   query ProjectQuickLinksQL($input: SiloSearchInput!) {
//     searchSkills(input: $input) {
//       category
//       headings
//       skill
//       keyword
//       anchorText
//       supporting
//     }
//   }
// `;
