import { Loader } from 'components/Loader';
import React, { ReactElement } from 'react';
import styles from './AuthLayout.module.scss';

interface Props {
  progress?: number;
  isLoading?: boolean;
  leftSide?: ReactElement;
  rightSide?: ReactElement;
}

export const AuthLayout: React.FC<Props> = ({ leftSide, rightSide, progress, isLoading }) => {
  return (
    <div className="auth-container d-flex h-100">
      <div className="auth-col auth-left">
        {isLoading && <Loader className={styles.loader} />}
        {progress && (
          <div className="auth-progress-line">
            <div className="auth-progress-value" style={{ width: `${progress}%` }} />
          </div>
        )}
        <div className="auth-card card-wide">{leftSide}</div>
      </div>
      <div className="auth-col auth-right w-600 d-none d-lg-block">{rightSide}</div>
    </div>
  );
};
