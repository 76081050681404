import React from 'react';
import { Button, Col, Form, FormGroup, Row } from 'reactstrap';
import { MeetingSlot } from '../../../types/project-request-types';
import { ReactComponent as Logo } from '../../../svg/mt-logo-dark.svg';
import { SingleDatePicker } from 'react-dates';
import { Moment } from 'moment';
import { TimeSlotPicker } from 'components/TimeSlotPicker';
import './StartDemo2.scss';

interface Props {
  meetingSlots: MeetingSlot[];
  availableDates: Moment[];
  selectedDate: Moment | null;
  onDateSelect: (date: Moment | null) => void;
  onTimezoneChange: (offset: number) => void;
  onSubmit: (meetingSlot: MeetingSlot) => void;
  goBack: () => void;
}

export const StartDemo2: React.FC<Props> = ({
  availableDates,
  meetingSlots,
  selectedDate,
  onDateSelect,
  onSubmit,
  goBack,
  onTimezoneChange,
}) => {
  const [selected, setSelected] = React.useState<MeetingSlot | null>(null);
  const [, setFocus] = React.useState<{ focused: boolean }>({ focused: false });

  return (
    <Form>
      <div className="auth-box">
        <div className="auth-headline">
          <strong className="auth-logo">
            <Logo />
          </strong>
        </div>
        <h1>Meet with MindTrust.</h1>
        <div className="date_picker_wrapper">
          <SingleDatePicker
            id="date-picker"
            date={selectedDate}
            isOutsideRange={(date) => !availableDates.find((ad) => !ad.diff(date, 'day'))}
            onDateChange={onDateSelect}
            onFocusChange={setFocus}
            numberOfMonths={1}
            placeholder="Select start date"
            displayFormat="MM-DD-YYYY"
            transitionDuration={700}
            hideKeyboardShortcutsPanel
            readOnly
            focused
            dayPickerNavigationInlineStyles={{
              display: 'flex',
              justifyContent: 'space-between',
            }}
          />
        </div>
        {selectedDate && (
          <TimeSlotPicker
            className="mt-3"
            slots={meetingSlots}
            selected={selected}
            onSelect={setSelected}
            onTimezoneChange={onTimezoneChange}
          />
        )}
      </div>
      <div className="auth-actions">
        <FormGroup>
          <Row className="gap-xs">
            <Col xs={6}>
              <Button className="btn-block c-gray" color="default" onClick={goBack}>
                <i className="btn-icon-back" />
                Back
              </Button>
            </Col>
            <Col xs={6}>
              <Button
                className="btn-block"
                color="primary"
                disabled={!selected}
                onClick={() => selected && onSubmit(selected)}
              >
                Next
                <i className="btn-icon-next" />
              </Button>
            </Col>
          </Row>
        </FormGroup>
      </div>
    </Form>
  );
};
