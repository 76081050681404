import React from 'react';
import { observer } from 'mobx-react-lite';
import { useAppStore } from 'store/app-store.hook';
import { InvintationForm } from 'components/InvintationForm';
import { Row, Col, Button } from 'reactstrap';
import { Helmet } from 'react-helmet';
import { Role } from 'types/gql-generated';
import { Loader } from 'components/Loader';

export const DashboardPage: React.FC = observer(() => {
  const { authStore, referralsStore, isSyncing, syncSilo } = useAppStore();

  const isSuperAdmin = authStore.me?.roles.find((r) => r === Role.MT_SUPER_ADMIN_OWNER);
  return (
    <>
      <Helmet>
        <title>Dashboard</title>
      </Helmet>
      <h1 className="mb-5 mt-5">DashboardPage</h1>
      <Row>
        <Col>
          <InvintationForm onInvite={referralsStore.inviteUser} />
          {isSuperAdmin && (
            <div>
              <h5 className="text-center">
                This button is syncing silo sh3eadsheet with DB and available for super admins only
              </h5>
              <Button className="w-100" onClick={syncSilo}>
                {isSyncing ? <Loader /> : 'Sync Silo'}
              </Button>
            </div>
          )}
        </Col>
        <Col>
          <h2>Your info</h2>
          <pre>{JSON.stringify(authStore.me, null, 2)}</pre>
        </Col>
      </Row>
    </>
  );
});
