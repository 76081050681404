import { ServicesStore } from 'store/services/services.store';
import { BookedMeeting, BookMeetingParams, MeetingInfo } from 'types/request.types';
import { DomainsStore } from './domains.store';

export class RequestDemoDomain {
  constructor(private domainStore: DomainsStore, private servicesStore: ServicesStore) {}

  loadMeetingInfo(): Promise<MeetingInfo> {
    return this.servicesStore.requestService.bookMeetingInfo();
  }

  bookMeeting(input: BookMeetingParams): Promise<BookedMeeting> {
    return this.servicesStore.requestService.bookMeeting(input);
  }
}
