import React from 'react';
import { Helmet } from 'react-helmet';
import { StartProject4 } from 'components/ProjectRequest';
import { observer } from 'mobx-react-lite';
import { useAppStore } from 'store/app-store.hook';

export const ProjectRequest4Page = observer(() => {
  const { projectRequestPage } = useAppStore().ui;
  return (
    <>
      <Helmet>
        <title>Request Project</title>
      </Helmet>
      <StartProject4
        requestStep4={projectRequestPage.projectRequest}
        onNext={projectRequestPage.process4Step}
        goBack={projectRequestPage.goBack}
      />
    </>
  );
});
