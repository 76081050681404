import React from 'react';
import { Link } from 'react-router-dom';
import { Nav, NavItem } from 'reactstrap';
import './SubMenu.scss';
import { useAppStore } from 'store/app-store.hook';
import { observer } from 'mobx-react-lite';

interface Props {
  items: {
    href: string;
    label: string;
  }[];
  fullMatch?: boolean;
}

const isActive = (path: string, href: string) => path === href;

export const SubMenu: React.FC<Props> = observer(({ items, fullMatch }) => {
  const { pathname, search } = useAppStore().routerStore.location;
  const path = fullMatch ? `${pathname}${search}` : pathname;
  return (
    <Nav className="sub-menu">
      {items.map(({ label, href }) => (
        <NavItem key={href}>
          <Link className={isActive(path, href) ? 'active' : ''} to={href}>
            {label}
          </Link>
        </NavItem>
      ))}
    </Nav>
  );
});
