import React from 'react';
import { Helmet } from 'react-helmet';
import { observer } from 'mobx-react-lite';
import { useAppStore } from 'store/app-store.hook';
import { StartDemo2 } from 'components/ProjectRequest/StartDemo2';

export const DemoRequest2Page = observer(() => {
  const { demoRequestPage } = useAppStore().ui;

  React.useEffect(() => {
    demoRequestPage.loadMeetingInfo();
  }, [demoRequestPage]);

  return (
    <>
      <Helmet>
        <title>Request Project</title>
      </Helmet>
      <StartDemo2
        availableDates={demoRequestPage.availableDates}
        meetingSlots={demoRequestPage.meetingSlots}
        selectedDate={demoRequestPage.selectedDate}
        onDateSelect={demoRequestPage.onDateSelect}
        goBack={demoRequestPage.goBack}
        onTimezoneChange={demoRequestPage.onTimezoneChange}
        onSubmit={demoRequestPage.process2Step}
      />
    </>
  );
});
