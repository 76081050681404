import React from 'react';
import classNames from 'classnames';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { Button, Col, Form, FormGroup, Row } from 'reactstrap';
import { Link, useLocation } from 'react-router-dom';
import { validate } from '../../../utils/form-utils';
import { ReactComponent as Logo } from '../../../svg/mt-logo-dark.svg';
import { requestDemoRoutes, requestProjectRoutes } from 'routing';
import { observer } from 'mobx-react-lite';
import { MultipleSelect } from 'components/MultipleSelect/MultipleSelect';
import styles from './StartProject2ResourceBased.module.scss';
import { Step2SkillsProjectRequest } from 'types/request.types';

const validationSchema = () =>
  Yup.object()
    .shape({
      skills: Yup.array().of(Yup.string().required()).nullable().notRequired(),
    })
    .required();

interface Props {
  requestStep2: Step2SkillsProjectRequest;
  skills: string[];
  onNext: (params: Step2SkillsProjectRequest) => void;
  goBack: () => void;
  loadMoreSkills: (input?: string) => void;
}

export const StartProject2ResourceBased: React.FC<Props> = observer(
  ({ requestStep2, skills, onNext, goBack, loadMoreSkills }) => {
    const { search } = useLocation();
    const formik = useFormik<Step2SkillsProjectRequest>({
      enableReinitialize: true,
      initialValues: { ...requestStep2 },
      onSubmit: onNext,
      validate: validate<Step2SkillsProjectRequest>(validationSchema),
    });
    const { values, errors, touched, handleSubmit, setFieldValue } = formik;

    const onSelect = React.useCallback(
      (values: string[]) => {
        setFieldValue('skills', values, true);
      },
      [setFieldValue],
    );

    return (
      <Form onSubmit={handleSubmit}>
        <div className="auth-box">
          <div className="auth-headline">
            <strong className="auth-logo">
              <Logo />
            </strong>
            <h1>Which roles/skills are you looking for on your team?</h1>
            <p>
              I'd rather select a specific <Link to={`${requestProjectRoutes.request(2)}${search}`}>project type</Link>{' '}
              instead.
            </p>
          </div>
          <FormGroup
            className={classNames(
              'form-group-addon',
              { 'input-active': values.skills },
              { 'input-invalid': touched.skills && !!errors.skills },
              { 'input-touched': touched.skills },
              { 'input-valid': touched.skills && !errors.skills },
            )}
          >
            <MultipleSelect
              className={styles.multiple_select}
              selected={values.skills || []}
              options={skills}
              onSelect={onSelect}
              loadOptions={loadMoreSkills}
            />
          </FormGroup>
        </div>
        <div className="auth-actions">
          <FormGroup>
            <Row className="gap-xs">
              <Col xs={6}>
                <Button className="btn-block c-gray" color="default" onClick={goBack}>
                  <i className="btn-icon-back" />
                  Back
                </Button>
              </Col>
              <Col xs={6}>
                <Button
                  className="btn-block"
                  type="submit"
                  color="primary"
                  disabled={!(values.skills || []).length}
                  onClick={handleSubmit}
                >
                  Next
                  <i className="btn-icon-next" />
                </Button>
              </Col>
            </Row>
          </FormGroup>
        </div>
        <p className="help-text">
          Need help? <Link to={requestDemoRoutes.request(2)}>Speak with a Sales Consultant.</Link>
        </p>
      </Form>
    );
  },
);
